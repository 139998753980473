import { Box, Divider } from "@mui/material";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import styles from "../styling/styles";
import { MainText, SubText } from "../texts";
import FanGoCard from "./FanGoCard";

const dividerStyle = {
  width: "50%",
  mx: {
    xs: "auto",
    sm: 4,
    md: 4,
    lg: 4,
  },
};

const bioBodyStyle = {
  ml: { xs: 0, sm: 5, md: 5, lg: 5 },
  textAlign: styles.alignStyleCLLL,
};

const cardBodyStyle = { mt: -8, pb: 5 };

const bioTextPaddingX = {
  xs: 0,
  sm: 4,
  md: 4,
  lg: 4,
};

const FanGoBioCard = ({
  avatar,
  name,
  job,
  avatarDesc = null,
  bio,
  minHeight = null,
}) => {
  return (
    <Box>
      <Box sx={bioBodyStyle}>
        <GatsbyImage image={avatar} alt={avatarDesc || undefined} />
      </Box>
      <Box sx={cardBodyStyle}>
        <FanGoCard minHeight={minHeight} buttonEffect={false}>
          <MainText
            textVariant="h3"
            fontColor={styles.darkBlue}
            value={name}
            textAlignment={styles.alignStyleCLLL}
            marginY={styles.allZero}
            paddingX={bioTextPaddingX}
            paddingTop={styles.allTen}
          />
          <SubText
            variant="h5"
            fontColor={styles.grey}
            value={job}
            textAlignment={styles.alignStyleCLLL}
            marginTop={1}
            paddingX={bioTextPaddingX}
          />
          <Divider sx={dividerStyle} />
          <SubText
            variant="subtitle1"
            fontColor={styles.grey}
            value={bio}
            textAlignment={styles.alignStyleLLLL}
            paddingX={bioTextPaddingX}
          />
        </FanGoCard>
      </Box>
    </Box>
  );
};

export default FanGoBioCard;
