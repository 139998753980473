import { graphql } from 'gatsby';
import React, { useContext } from 'react';

import StandardSEO from '../components/common/seo/StandardSEO';
import Separator from '../components/common/styling/Separator';
import styles from '../components/common/styling/styles';
import TicketingFeatureBody from '../components/PageComps/AuditoriumPageComp/TicketingFeatureBody';
import { TrustedBrandIcons } from '../components/PageComps/CommonComps';
import FullImageBackgroundHeader from '../components/PageComps/CommonComps/heroSections/FullImageBackgroundHeader';
import BasicTitleSubTitle from '../components/PageComps/CommonComps/heroSections/heroSectionComps/BasicTitleSubTitle';
import { DataContext } from '../providers/DataProvider';

const AuditoriumPage = ({ data }) => {
  const { sanityAuditoriumPage } = data;
  const { pageHead } = sanityAuditoriumPage;
  const { title, subTitle } = pageHead;
  const { heroSectionBackgroundImage: imageData } = useContext(DataContext);

  return (
    <>
      <FullImageBackgroundHeader
        backgroundPosition="5% 50%"
        imageData={imageData}
        content={
          <BasicTitleSubTitle
            title={title}
            subTitle={subTitle}
            subTitlePlainText={true}
            titleRowSpacing={undefined}
            titlePaddingBottom={30}
            largeSubTitleSize
          />
        }
      />

      <TicketingFeatureBody data={sanityAuditoriumPage.ticketing} />

      <Separator height={100} />

      <TrustedBrandIcons
        data={data.sanityCommonContent.trustedBy}
        paddingBottom={styles.allEight}
      />
    </>
  );
};

export default AuditoriumPage;

export const Head = ({ data }) => (
  <StandardSEO SEOData={data.sanityAuditoriumPage.seo} />
);

export const query = graphql`
  query ($language: String!) {
    sanityAuditoriumPage(language: { eq: $language }) {
      seo {
        title
        description
        keywords
        name
        type
      }
      pageHead {
        title
        subTitle
      }
      ticketing {
        title
        ticketingFeatures {
          image {
            asset {
              gatsbyImageData(placeholder: DOMINANT_COLOR, height: 350)
            }
            altText
          }
          body
          title
        }
      }
    }
    sanityCommonContent(language: { eq: $language }) {
      trustedBy {
        title
      }
    }
  }
`;
