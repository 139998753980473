import React from "react";
import Body from "../../common/body/Body";
import styles from "../../common/styling/styles";
import { MainText, SubText } from "../../common/texts";
import { Box, Grid } from "@mui/material";
import { FanGoCard } from "../../common/fanGoCard";
import { GatsbyImage } from "gatsby-plugin-image";
import DemoButton from "../../common/button/DemoButton";

const TicketingFeatureBody = ({ data }) => {
  const { title, ticketingFeatures } = data;

  return (
    <Body backgroundColor={styles.lightBlue} noAnimation>
      <MainText
        textVariant="h2"
        value={title}
        marginY={styles.allTwo}
        fontColor={styles.darkBlue}
      />

      <Grid
        container
        justifyItems="center"
        sx={{
          margin: "auto",
          textAlign: { xs: "center", md: "left" },
        }}
      >
        {ticketingFeatures.map((feature, index) => {
          const { image, title, body } = feature;

          let selectImage = null;
          let altText = "";

          if (image) {
            const { asset } = image;
            altText = image.altText;
            selectImage = asset.gatsbyImageData;
          }

          return (
            <Grid
              key={index}
              item
              xs={12}
              md={6}
              sx={{
                pl: { xs: 0, md: index % 2 !== 0 ? 5 : 0 },
                mt: { xs: 5, md: index % 2 !== 0 ? 10 : 5 },
              }}
            >
              <FanGoCard
                buttonEffect={false}
                height={{ xs: "100%", md: 750 }}
                backgroundColor={index % 2 !== 0 ? styles.blogBlue : "white"}
              >
                <Box sx={{ p: 5 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {image && (
                      <GatsbyImage
                        image={selectImage}
                        alt={altText}
                        objectFit="contain"
                      />
                    )}
                  </Box>
                  <MainText
                    textAlignment={styles.alignStyleCCLL}
                    textVariant="h5"
                    value={title}
                    marginY={styles.allTwo}
                    fontColor={index % 2 !== 0 ? styles.white : styles.black}
                  />

                  <SubText
                    textAlignment={styles.alignStyleCCLL}
                    variant="body1"
                    marginY={styles.allTwo}
                    fontColor={index % 2 !== 0 ? styles.white : styles.grey}
                    value={body}
                  />
                </Box>
              </FanGoCard>
            </Grid>
          );
        })}
      </Grid>

      <Box sx={{ textAlign: "center", pt: styles.allFifteen }}>
        <DemoButton buttonName="Request a Demo" fontSize={25} />
      </Box>
    </Body>
  );
};

export default TicketingFeatureBody;
