import { Card, CardActionArea, CardContent } from "@mui/material";
import React from "react";
import styles from "../styling/styles";

const FanGoCard = ({
  buttonEffect = true,
  width = null,
  height = null,
  children,
  maxWidth = null,
  minHeight = null,
  backgroundColor = null,
  paddingX = styles.allZero,
  paddingTop = 0,
  marginBottom = 0,
}) => {
  const cardStyle = {
    height: height || "100%",
    width: width || "100%",
    maxWidth: maxWidth || "100%",
    boxShadow: "rgba(140, 152, 164, 0.25) 0px 3px 6px 0px",
    mb: marginBottom,
    minHeight: minHeight || undefined,
    backgroundColor: backgroundColor || undefined,
    pt: paddingTop,
  };

  return (
    <Card sx={cardStyle}>
      {buttonEffect ? (
        <CardActionArea sx={{ px: paddingX, height: "100%" }}>
          <CardContent>{children}</CardContent>
        </CardActionArea>
      ) : (
        <CardContent sx={{ px: paddingX, height: "100%" }}>
          {children}
        </CardContent>
      )}
    </Card>
  );
};

export default FanGoCard;
